<template>
  <div>
    <div class="row">
      <div>
        <b-carousel
          id="carousel-1"
          v-if="this.currentRoomInfo.images"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <!-- Text slides with image -->
          <b-carousel-slide
            v-for="image in this.currentRoomInfo.images.data"
            :key="image.id"
            :img-src="image.url"
          ></b-carousel-slide>
        </b-carousel>

        <h2>{{ this.currentRoomInfo.name }}</h2>

        <div v-if="this.currentRoomInfo.amenities" style="margin-bottom: 20px;">
          <v-chip
            class="ma-2 one-info-chip"
            outlined
            v-for="amenity in this.currentRoomInfo.amenities"
            :key="amenity.id"
          >
            <v-icon left>mdi-information-outline</v-icon>
            {{ amenity }}
          </v-chip>
        </div>

        <v-chip class="ma-2 one-info-chip" outlined>
          <v-icon left>mdi-seat-outline</v-icon>
          {{ this.currentRoomInfo.places }}
        </v-chip>

        <p>{{ this.currentRoomInfo.details }}</p>

        <!-- book room -->
        <div class="row">
          <div class="col-xl-6">
            <h4>Rezerva sala</h4>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-6">
            <div class="form-group">
              <label>De la </label>
              <b-form-input
                id="type-date"
                type="date"
                ref="date_start"
              ></b-form-input>
              <b-form-input
                id="input-2t"
                type="time"
                placeholder="09:00"
                ref="time_start"
              ></b-form-input>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="form-group">
              <label>Pana la</label>
              <b-form-input
                id="type-date"
                type="date"
                ref="date_end"
              ></b-form-input>
              <b-form-input
                id="input-3t"
                type="time"
                placeholder="19:00"
                ref="time_end"
              ></b-form-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-6">
            <button
              v-on:click="submit"
              class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-submit"
            >
              Rezervă
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
h2,
p {
  margin: 40px 0 20px;
}

.v-chip.one-info-chip {
  border-color: purple !important;
  color: purple !important;
  margin-right: 20px;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
}

.form-group label {
  width: 100%;
}

.form-group input {
  width: 50%;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_SINGLE_ROOM } from "../../core/services/store/room.module";
import { DO_BOOKING } from "../../core/services/store/booking.module";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      slide: 0,
      sliding: null
    };
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Săli", route: "list-rooms" },
      { title: "Sala" }
    ]);
    this.$store.dispatch(GET_SINGLE_ROOM, [{ id: this.$route.params.id }]);
    //console.log(this.currentRoomInfo);
  },
  computed: {
    ...mapGetters(["currentRoomInfo"]),
    getRoom() {
      return this.currentRoomInfo;
    }
  },
  methods: {
    submit: function(e) {
      e.preventDefault();

      var startTime =
        this.$refs.date_start.localValue +
        " " +
        this.$refs.time_start.localValue;
      var finishTime =
        this.$refs.date_end.localValue + " " + this.$refs.time_end.localValue;

      let room = new FormData();
      room.set("room_id", this.currentRoomInfo.id);
      room.set("date_start", startTime);
      room.set("date_finish", finishTime);
      room.set("places", this.currentRoomInfo.places);

      // set spinner to submit button

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store
          .dispatch(DO_BOOKING, room)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Rezervarea a fost efectuata cu succes!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false
            });
          })
          .catch(() => {
            Swal.fire({
              title: "",
              text: "Sala nu este disponibila la data aleasa.",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false
            });
          });
      }, 2000);
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    }
  }
};
</script>
